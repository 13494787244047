@import 'variables';

$level2: #f3ede5;
$level3: #f6f2ed;

.details-toggle-button {
  font-size: 1rem;
  color: $black;
  font-weight: bold;
  background-color: $gray-200 !important;
  &:hover {
    color: white;
    background-color: $pink !important;
  }
  &:disabled {
    opacity: 1;
  }
}

.details-header {
  color: $black;
  background-color: $gray-100;
  border-radius: 5px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  &:hover {
    color: $black;
    background-color: $gray-200;
    > .details-toggle-button:disabled {
      background-color: $pink !important;
      color: white;
    }
  }
}

.details {
  overflow-wrap: anywhere;

  // level 1
  .details-background {
    background-color: $gray-400;
  }
  .details {
    // level 2
    .details-background {
      background-color: $level2;
    }

    .details {
      // level 3
      .details-background {
        background-color: $level3;
      }
    }
  }
}

.element-header {
  font-size: 12px;
  color: $black;
  opacity: 50%;
}
