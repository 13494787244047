$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1018px,
  //lg: 992px, is to small for the top menu
  xl: 1200px,
  xxl: 1400px,
);

$text: #231b10;
$pink: #d61040;
$bg: #fbf9f6;
$btn: #f7f3ed;
$btn-hover: #ede5d9;
$btn-pressed: #e5d6c3;

$btn-selected: $btn-hover;
$btn-selected-hover: #e5d6c3;
$btn-selected-pressed: #ddcab0;

$btn-destructive: #fff0f4;
$btn-destructive-pressed: #d51041;

$black: #181c32;
$light-cyan: #e6f4f1;

$gray-100: #fbf9f6; // Background
$gray-200: #f7f3ed; // Button
$gray-300: #f9f2e7; // Button hover
$gray-400: #ede5d9; // Button selected

$primary: $pink;
$secondary: $gray-200;
$success: #3ade50;
$dark: $black;

$body-bg: $gray-100;
$body-color: $black;
$font-family-sans-serif:
  Poppins Regular,
  system-ui,
  -apple-system,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  'Noto Sans',
  'Liberation Sans',
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji' !default;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
);

$border-radius: 0.5rem;
$border-radius-sm: 0.333rem;
$border-radius-lg: 0.666rem;
$border-radius-xl: 1.333rem;
$border-radius-xxl: 2.666rem;
$border-radius-pill: 50rem;

// Gray backgrounds
@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: $value !important;
  }
}

// Borders
@each $color, $value in $grays {
  .border-gray-#{$color} {
    border-color: $value !important;
  }
}

$navbar-padding-y: null;
$navbar-padding-x: null;
$nav-link-color: $black;
$nav-link-hover-color: $gray-100;
$nav-pills-link-active-color: $black;
$nav-pills-link-active-bg: $gray-100;
$btn-color: $black;

$btn-margin-bottom: 10px;

$card-bg-danger: #fff0f4;
$card-text-danger: $black;
$icon-padding: 8px;
$card-bottom: 15px;
$language-settings-padding: 15px;

$btn-padding: 15px;
$btn-text-align: left;

$off-canvas-header-justify: end;
$off-canvas-header-btn-bg: $gray-100;
$off-canvas-header-btn-border: $gray-100;
$icon-close-size: 2em;
$icon-close-color: $pink;

$enable-negative-margins: true;
