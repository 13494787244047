@import 'variables';

.card.text-danger {
  margin-bottom: $card-bottom;
  border-color: #f1416c;

  .card-body {
    color: $card-text-danger !important;

    a.btn {
      padding: $card-bottom;
      text-align: left;
      margin-bottom: $card-bottom;
      background-color: $pink;

      fa-icon {
        padding: $icon-padding;
      }
    }
  }
}

.card {
  border-width: 4px;
  margin-bottom: $card-bottom;
}

.card-danger {
  --bs-card-border-color: #fbe1e4;
  --bs-card-bg: #fbf1f0;
  --bs-card-spacer-y: 1.5rem;
}

.card-warning {
  --bs-card-border-color: #ffc103;
  --bs-card-bg: #fff7bf;
  --bs-card-color: #bc6f0b;
}

.card-home-page {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  border: solid 1px #{$gray-200};
  border-top: 0;
  min-height: 100px;
}

.card-home-page-container {
  border-width: 4px;
  border-style: solid;
  border-color: #f7f3ed;
  border-radius: 8px;
  padding: 24px;
  position: relative;
}
