@import 'variables';

.offcanvas-header {
  justify-content: $off-canvas-header-justify;

  a {
    border-color: $off-canvas-header-btn-border;
    background-color: $off-canvas-header-btn-bg;

    fa-icon {
      font-size: $icon-close-size;
      color: $icon-close-color;
    }
  }
}
