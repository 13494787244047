.home-page-tool-tip .tooltip-inner {
  background-color: white;
  font-family: 'Poppins', 'Poppins Placeholder', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.2em;
  color: black;
  text-decoration: none;
  text-transform: none;
}
