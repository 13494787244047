@use '@ag-grid-community/styles' as ag;

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('/assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PT Mono';
  font-weight: 400;
  src: url('/assets/fonts/PTMono-Regular.ttf') format('truetype');
}

* {
  font-family: 'Poppins';
}

@import './sass/variables';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.offcanvas {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-right: env(safe-area-inset-right);
}

.fixed-top {
  padding-top: env(safe-area-inset-top) !important;
  padding-left: env(safe-area-inset-left) !important;
  padding-bottom: 0 !important;
}

footer {
  margin-right: calc(-1 * env(safe-area-inset-right)) !important;
  margin-left: calc(-1 * env(safe-area-inset-left)) !important;
  margin-bottom: calc(-1 * env(safe-area-inset-bottom));
  padding-right: env(safe-area-inset-right) !important;
  padding-left: env(safe-area-inset-left) !important;
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.mobile {
  height: 100%;
  width: 100%;
  background-color: $gray-100;
}

.main {
  .spacer {
    min-width: 21.5rem;
    width: 21.5rem;
  }

  min-height: calc(100vh - 96px);
}

.side {
  width: 23rem;
  display: block !important;
  max-width: 23rem;
  min-width: 23rem;

  .content {
    padding-top: 6px;
  }
}

.table-hover {
  height: 1px; // Needed for full cell height links in empty cells

  td {
    a {
      display: block;
      color: inherit;
      text-decoration: inherit;
      height: 100%;
    }
  }
}

.onboard-image {
  width: 100%;
  height: auto;
  max-width: 400px;
}

@include ag.grid-styles(
  (
    theme: 'alpine',
  )
);
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'bootstrap/scss/bootstrap';
@import './sass/alert';
@import './sass/accordion';
@import './sass/ag-grid';
@import './sass/buttons';
@import './sass/dropdown';
@import './sass/navbar';
@import './sass/link';
@import './sass/navbar';
@import './sass/type';
@import './sass/card';
@import './sass/material-icons';
@import './sass/offcanvas';
@import './sass/resource-details';
@import './sass/modal';
@import './sass/sizing';
@import './sass/sections';
@import './sass/input';
@import './sass/tooltip';

// Small spacer on mobile, and then show at the `lg` breakpoint
.spacer-top {
  min-height: 5rem;
}

.header-menu-icon {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.back-arrow {
  line-height: 0;
  mat-icon {
    font-size: 40px !important;
  }
}
@include media-breakpoint-up(lg) {
  .spacer-top {
    min-height: 6.25rem;
  }

  .header-menu-icon {
    height: 3.75rem !important;
    width: 3.75rem !important;
  }

  .back-arrow {
    line-height: 0;
    mat-icon {
      font-size: 60px !important;
    }
  }
}

.entry-select {
  min-width: 78px;
}

.no-wrap {
  white-space: nowrap;
}

.modal-icon {
  width: 60px;
  height: 60px;
}

.fingerprint-icon {
  width: 78px;
  height: 78px;
}

.offline-status-icon {
  width: 24px;
  height: 24px;
}

.offline-status {
  position: fixed;
  left: 0;
  right: 0;
  background-color: rgba(24, 28, 51, 0.1);
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(24, 28, 51, 0.1);
  z-index: 10000;
  color: $black;
  font-family: 'Poppins Regular';
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .offline-status-icon {
    margin-right: 0.5rem;
    color: $pink;
  }
}

.medmij-logo {
  min-width: 128px;
  height: 49px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/medmij-logo.png');
}

.opt-logo {
  min-width: 300px;
  height: 49px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/opt-in-out.png');
}

.wellbeing-logo {
  min-width: 300px;
  height: 49px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/wellness.png');
}

.pathways-logo {
  min-width: 300px;
  height: 49px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/pathways.png');
}

.digital-care-guide-logo {
  min-width: 156px;
  height: 42px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/dzg.png');
}

@media (max-width: 767px) {
  .offline-status {
    top: 0;
    bottom: auto;
  }
}

@media (min-width: 768px) {
  .offline-status {
    top: auto;
    bottom: 0;
  }
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

.text-link-like {
  text-decoration: underline;
  cursor: pointer;
}

.text-link-like:hover {
  text-decoration: none;
}

.flex-basis-0 {
  flex-basis: 0;
}

@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .flex-#{$name}-basis-0 {
      flex-basis: 0;
    }
  }
}

.flex-basis-auto {
  flex-basis: auto;
}
@each $name, $value in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    .flex-#{$name}-basis-auto {
      flex-basis: auto;
    }
  }
}

.delimiter {
  width: 35%;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 10px;
  margin: 18px auto;
}

hr {
  border-color: $btn-pressed;
  border-width: 2px;
}

.icon-rotate-15deg {
  transform: rotate(15deg);
  position: absolute;
  right: -18px;
  top: 6px;
  width: 80px;
  z-index: 1;
  svg {
    width: 80px;
    height: 80px;
  }
}

.icon-upsell {
  position: absolute;
  right: -20px;
  top: -10px;
  width: 80px;
  z-index: 1;
  svg {
    width: 80px;
    height: 80px;
  }
}

.home-page {
  h2 {
    color: rgb(133, 122, 110);
  }
}

.toast-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1055;
  display: flex;
  flex-direction: column-reverse;
  align-items: left;
  padding: 0 16px;
  box-sizing: border-box;
  text-align: left;
}

.toast-container ngb-toast.toast-custom {
  background-color: #e5d6c3;
  color: #231b0f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  border-radius: 8px;
  border: none;
}

.toast-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toast-icon {
  font-size: 36px !important;
  width: 36px !important;
  height: 36px !important;
  color: #231b0f;
  flex-shrink: 0;
}

.toast-message {
  flex: 1;
  text-align: left;
}

@supports (bottom: env(safe-area-inset-bottom)) {
  .toast-container {
    bottom: calc(env(safe-area-inset-bottom) + 20px);
    padding-left: 16px;
    padding-right: 16px;
  }
}

ngb-toast {
  max-width: 100%;
  margin-bottom: 10px;
  padding: 0.5rem 1rem;
  text-align: left;
  box-sizing: border-box;
}

body iframe {
  height: 100%;
}

@media (display-mode: fullscreen) {
  body iframe {
    padding: 0;
    height: 100%;
  }
}

@media (display-mode: standalone) {
  body iframe {
    padding: 0;
    height: 100%;
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .warning-update {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: var(--safe-area-inset-top);
  }
}

@supports (padding-top: constant(safe-area-inset-top)) {
  .warning-update {
    --safe-area-inset-top: constant(safe-area-inset-top);
    margin-top: var(--safe-area-inset-top);
  }
}

.warning-update {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #d61040;
  color: white;
  padding: 10px;
  z-index: 10000;
}

.warning-content {
  display: flex;
  align-items: center;
}

.warning-icon {
  aspect-ratio: 1 / 1;
  flex: none;
  height: 44px;
  width: 44px;
  margin-right: 10px;
}

.update-link {
  color: white;
}

.warning-text {
  display: block;
  overflow-wrap: break-word;
}

.textarea-scroll {
  overflow-y: auto;
  height: 100px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  -webkit-overflow-scrolling: touch;
}

.header-break {
  overflow-wrap: anywhere;
  hyphens: auto;
}

.medmij-certified {
  min-width: 79px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/images/medmij-logo.png');
}

.sources-footer {
  font-size: 11px;
}

.sources-intro {
  font-size: 18px;
}
