@import 'variables';

.ag-cell-wrap-text {
  line-height: 1.5 !important; // The default line height is insane
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ag-root-wrapper {
  border: solid 1px #{$gray-200};
  border-bottom: 0;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ag-cell:focus {
  border: none !important;
  outline: none;
}

.ag-theme-alpine {
  --ag-borders: none;
  --ag-header-background-color: #{$gray-200};
  --ag-odd-row-background-color: white;
  --ag-row-hover-color: #{$gray-100};
  --ag-row-border-color: #{$gray-200};
  --ag-foreground-color: #{$black};
  --ag-header-foreground-color: #{$black};
  --ag-font-size: 14px;
  --ag-font-family: 'Poppins Regular';
}
@include media-breakpoint-down(sm) {
  .ag-theme-alpine {
    --ag-cell-horizontal-padding: 0.5rem;
  }
}

.circle-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1em;
  text-align: center;
  color: rgb(251, 249, 246);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgb(221, 202, 176);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.manifest-open {
  background-color: rgb(251, 249, 246);
}

.document-reference {
  background-color: rgb(247, 243, 237);
}

.document-border {
  box-shadow: rgb(247, 243, 237) 0px -1px 0px 0px inset;
  border-color: rgb(237, 229, 217);
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  width: 100%;
  transform: none;
  transform-origin: 50% 50% 0px;
  opacity: 1;
}

.document-border.document-border-bottom {
  border-bottom-width: 3px;
}

.document-border.document-border-top {
  border-top-width: 3px;
}

.grid-footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: white;
  border: solid 1px #{$gray-200};
  border-top: 0;
  min-height: 100px;

  h3 {
    color: #897a6e;
  }
}

.button-no-rows-container {
  border-width: 4px;
  border-style: solid;
  border-color: #f7f3ed;
  border-radius: 8px;
  padding: 24px;
  position: relative;
}

.icon-health-records {
  transform: rotate(15deg);
  position: absolute;
  right: 0;
  top: 6px;
  width: 75px;
  padding-left: 11px;
  z-index: 1;
  svg {
    width: 67px;
  }
}
