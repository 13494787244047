.accordion {
  --bs-accordion-body-padding-x: 1rem 0;
  --bs-accordion-body-padding-y: 0;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-focus-border-color: none;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-border-width: 0;
  font-weight: 600;

  a {
    text-decoration: none;
  }
}

.accordion-button,
.accordion-header-item .accordion-header .collapsed {
  color: $text;
  background-color: $bg;
  margin-bottom: 10px;
  border-radius: 8px !important;
  font-weight: 600;
  &.active {
    color: $text !important;
    background-color: $btn-selected !important;
  }
  &:hover {
    color: $text;
    background-color: $btn-hover;
    &:active {
      color: $text !important;
    }
  }
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  color: $text;
}

.custom-header::after {
  content: none;
}

.middle-menu {
  background-color: #fbf9f6;
  font-size: 1.5rem;
}

.middle-menu.accordion-button {
  --bs-accordion-btn-icon: url('../assets/images/down-arrow-pink.svg');
  --bs-accordion-btn-active-icon: url('../assets/images/down-arrow-pink.svg');
  --bs-accordion-btn-icon-width: 2.5rem;
  &:hover {
    --bs-accordion-btn-icon: url('../assets/images/down-arrow-white.svg');
  }
}
