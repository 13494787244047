@import 'variables';

.editable-input .input-group-text {
  background-color: $gray-200 !important;
}

input.input-box,
select.select-box,
select.select-box option,
input.input-box:focus {
  background: $gray-200;
  font-weight: 600;
  padding: 0.75rem;
}

.form-control {
  --bs-border-width: 2px;
  --bs-body-bg: #{$gray-200};
}

.form-control:focus,
.form-control:active {
  --bs-border-color: #{$btn-selected-pressed} !important;
  --bs-body-bg: #{$gray-100} !important;
  --bs-box-shadow: none !important;
  border-color: #{$btn-selected-pressed} !important;
  box-shadow: none !important;
}
