/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('/assets/fonts/Material-Icons.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  src: url('/assets/fonts/Material-Symbols-Outlined.woff2') format('woff2');
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

.material-icons-check-circle {
  height: 90px !important;
  width: 90px !important;
  font-size: 90px;
}

mat-icon.mat-spin,
.mat-spin mat-icon {
  -webkit-animation: mat-spin 2s infinite linear;
  animation: mat-spin 2s infinite linear;
}
mat-icon.mat-pulse,
.mat-pulse mat-icon {
  -webkit-animation: mat-spin 1s infinite steps(8);
  animation: mat-spin 1s infinite steps(8);
}
@-webkit-keyframes mat-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes mat-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.mat-size-2 {
  width: 32px !important;
  height: 32px !important;
  font-size: 32px !important;
}

.mat-size-1 {
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
}
